<template>
  <header id="header" class="the-header">
    <div class="the-header__top">
      <div class="the-header__container">
        <div class="the-header__text">
          {{ $t("header.text") }}
          <a :href="$t('header.link.url')">{{ $t("header.link.text") }}</a>
        </div>

        <div class="the-header__email">
          <a :href="`mailto:${$t('header.email')}`">{{ $t("header.email") }}</a>
        </div>

        <app-languages class="the-header__languages" />
      </div>
    </div>

    <nav class="the-header__navigation">
      <div class="the-header__container">
        <a href="/" class="the-header__logo">
          <img src="/images/logo.png" alt="LOGO">
        </a>

        <app-menu class="the-header__menu" />
      </div>
    </nav>
  </header>
</template>

<script>
import AppLanguages from '@/components/app-languages';
import AppMenu from '@/components/app-menu';

export default {
  components: {
    AppLanguages,
    AppMenu,
  },
};
</script>

<style lang="scss">
.the-header {
  $root: &;

  position: relative;

  &__container {
    @include container;

    display: flex;
    align-items: center;
  }

  &__top {
    padding: 8px 0;
    font-size: 18px;
    color: $white;
    background-color: $red;
  }

  &__navigation {
    padding: 16px 0;
    background: $white;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__logo {
    flex: 0 1 auto;
    max-width: 100%;
    padding-right: 16px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__menu {
    flex: 0 1 auto;
    margin-left: auto;
  }

  &__text {
    flex: 1;
  }

  &__email {
    margin-left: 18px;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__languages {
    margin-left: 18px;
  }

  @include media-bp(tab) {
    &__email,
    &__languages {
      display: none;
    }
    &__logo {
      max-width: 167px;
    }
    &__navigation {
      padding: 12px 0;
    }
  }

  @include media-bp(mob) {
    &__navigation {
      padding: 6px 0;
    }
  }
}
</style>
