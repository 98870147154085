<template>
  <div class="main-layout">
    <the-header class="main-layout__header"/>

    <main class="main-layout__main">
      <router-view/>
    </main>
    <the-footer class="main-layout__footer"/>
  </div>
</template>

<script>
import TheHeader from '@/components/the-header';
import TheFooter from '@/components/the-footer';

export default {
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>

<style lang="scss">
.main-layout {
  display: flex;
  flex-direction: column;

  &__header {
    z-index: 100;
    position: sticky;
    top: 0;
  }

  &__main {
    flex: 1 0 auto;
  }
}
</style>
